<template>
    <div class="vue-template auth">
        <form>
            <h3>Import Contestants</h3>
            <div class="d-grid gap-3">
                <div class="form-group">
                    <label>New Event Name</label>
                    <input type="text" required class="form-control form-control-lg" v-model="newDbName" />
                </div>

                <div class="form-group">
                    <label>Select Excel File</label>
                    <input type="file" :disabled="disabled"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                        v-on:change="importExcel($event)" />
                </div>

                <div class="form-group" v-if="fileJson">
                    <pre class="mp">{{ fileJson }}</pre>
                </div>
                
                <button class="btn btn-outline-secondary" v-if="fileJson" @click="uploadData">Upload Data</button>
                <button class="btn btn-secondary" @click.prevent="onCancel" >Exit</button>

            </div>
        </form>
    </div>
</template>

<script>
    import XLSX from '../../node_modules/xlsx/dist/xlsx.full.min.js'
    import { db } from '@/services/firebase/index.js';
    
    export default  {
    name: 'Import',
    data: function data() {
        return {
          newDbName: '',
          fileJson: null,
          excelFile: null,
          newEventId: 0
        };
    },
    methods: {
      uploadData() {
        this.fileJson.forEach((doc) => {
                      doc.exteriorBody = 0;
                      doc.interior = 0;
                      doc.underhood = 0;
                      doc.wheels = 0;
                      doc.trunk = 0;
                      doc.total = 0;
              db.collection(this.dbName).add(doc).then(() => {
                this.addNewCollection();
              }).catch((error) => {
                  alert(error);
              });
          });
          this.$store.dispatch('setScoreDatabaseName', this.dbName);
          alert("Import successfully!");
          this.$router.push({ name: 'Score' });
      },
      importExcel(event) {
          const vm = this;
          this.countRecordsInCollection();
          this.excelFile = event.target.files[0];
          var reader = new FileReader();
          reader.onload = function (e) {
              var data = e.target.result;
              var workbook = XLSX.read(data, { type: 'binary' });
              let sheetName = workbook.SheetNames[0];
              let worksheet = workbook.Sheets[sheetName];
              vm.fileJson = XLSX.utils.sheet_to_row_object_array(worksheet);
          }
          reader.readAsBinaryString(this.excelFile);
      },
      onCancel() {
          this.$router.push({ name: 'Score' });
      },
      countRecordsInCollection() {
        const vm = this;
        db.collection('collectName').get().then(snap => {
         vm.newEventId = snap.size + 1;
        });
      },
      addNewCollection() {
        const vm = this;
        db.collection('collectName').add(this.newEvent).then(() => {
                    vm.updateUsersAssignedCollection();
                }).catch((error) => {
                    alert(error);
                });
      },
      updateUsersAssignedCollection() {
        
      }
    },
    computed: {
      disabled() {
          return this.newDbName.length == 0;
      },
      collectionName() {
        return this.newDbName + ' ' + new Date().getFullYear();
      },
      dbName() {
        return this.newDbName.replace(/ /g,"_") + '_' + new Date().getFullYear() + '_scores';
      },
      categoryName() {
        return this.$store.getters.getCategoryDatabaseName;
      },
      newEvent() {
        return {
          Categories: this.categoryName,
          Id: this.newEventId,
          Scores: this.dbName,
          Name: this.collectionName,
          activeTo: new Date(Date.now() + 12096e5)
        }
      }
    },
  };
</script>

<style>
    .mp {
      height: 250px;
       width: 290px;
    }
    pre {
      margin-bottom: 0
    }
</style>